import React, { Component } from "react";
import { debounce } from "lodash";
import config from "../../config";
import {
  Container,
  Navbar,
  NavbarToggler,
  Nav,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  InputGroup,
  Input,
  InputGroupText,
  Button,
  NavItem,
  FormGroup,
  Form,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { BiSearch } from "react-icons/bi";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { AiFillCloseSquare, AiOutlineAlignLeft } from "react-icons/ai";
import Cart from "../../images/cart-block.svg";
import Main_Logo from "../../images/HeaderLogo.svg";
import { connect } from "react-redux";
import { logoutWithJWT } from "../../redux/actions/auth/loginActions";
import { instanceOf } from "prop-types";
import {
  getAllMenu,
  CartB2CApi,
  SearchApi,
  congressClickLog,
} from "../../redux/actions/apiActions/apiAction";
import Autosuggest from "react-autosuggest";
import history from "../../app/history";
import LinkedIn from "../../images/LinkedIn-icon.svg";
import X_Logo from "../../images/X-icon.svg";

class Header extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      isMidheaderActive: true,
      isHeaderActive: true,
      scrolltop: 0,
      dropdownOpen: false,
      dropdownOpenPR: false,
      dropdownOpenHCP: false,
      dropdownOpenSA: false,
      dropdownOpenCE: false,
      cart: [],
      searchType: "resources",
      visit: this.props.cookies.get("visitSA") || null,
      loadOptions: [{}, {}],
      loading: false,
      search: "",
    };
    this.onSuggestionsFetchRequested = debounce(
      this.onSuggestionsFetchRequested.bind(this),
      1000
    );
    this.setMidheaderActive = this.setMidheaderActive.bind(this);
    this.setHeaderActive = this.setHeaderActive.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleCookie = () => {
    const { cookies } = this.props;
    cookies.set("visitSA", "gowtham", { path: "/" }); // setting the cookie
    this.setState({ visit: cookies.get("visitSA") });
  };
  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };
  togglePR = () => {
    this.setState({ dropdownOpenPR: !this.state.dropdownOpenPR });
  };
  toggleSA = () => {
    this.setState({ dropdownOpenSA: !this.state.dropdownOpenSA });
  };
  toggleHCP = () => {
    this.setState({ dropdownOpenHCP: !this.state.dropdownOpenHCP });
  };
  toggleCE = () => {
    this.setState({ dropdownOpenCE: !this.state.dropdownOpenCE });
  };
  componentDidMount = async () => {
    window.addEventListener("scroll", this.handleScroll);
    await this.props.getAllMenu("", 1, 1000);
    await this.props.CartB2CApi();
  };
  componentDidUpdate = (prevProps, prevState) => {
    const scrollTop2 = window.pageYOffset;

    if (scrollTop2 !== prevState.scrolltop) {
      this.setState({ scrolltop: scrollTop2 });
    }
  };

  setMidheaderActive(e) {
    this.setState((prevState) => ({
      isMidheaderActive: !prevState.isMidheaderActive,
    }));
  }

  setHeaderActive(e) {
    this.setState((prevState) => ({
      isHeaderActive: !prevState.isHeaderActive,
    }));
  }

  handleCongressClick = async () => {
    window.open(
      "https://evolve.eventoptions.co.za/register/sahc2024?p=a31b3ba0-0302-11ef-a75c-b35fd25e7251",
      "_blank"
    );
    await congressClickLog();
  };

  handleScroll(event) {
    const scrollTop = window.scrollTo;
    this.setState({
      scrolltop: scrollTop,
    });
  }
  //autosuggest functions
  onChange = (event, { newValue }) => {
    // console.log(event.nativeEvent, newValue, "eeeeeeeeee");
    if (event.nativeEvent && event.nativeEvent.inputType) {
      this.setState({ search: newValue });
    }
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    // console.log("here search-----", value);
    this.setState({ loading: true, loadOptions: [{}, {}] });

    let res = await SearchApi(this.state.searchType, value);
    if (res.status === 200 && res.data.data) {
      let loadOptions = [{}];
      if (this.state.searchType === "resources") {
        let { itemsCategories, itemsTopics, items } = res.data.data;

        if (itemsCategories && itemsCategories.length) {
          for (let i = 0; i < itemsCategories.length; i++) {
            if (itemsCategories[i].items_data[0]) {
              let find = loadOptions.find(
                (l) => l._id === itemsCategories[i].items_data[0]._id
              );
              // console.log(find, "items data");
              if (!find) {
                itemsCategories[i].items_data[0].label =
                  itemsCategories[i].items_data[0].Description;
                itemsCategories[i].items_data[0].redirect =
                  "/hcp/resource/details";
                loadOptions.push(itemsCategories[i].items_data[0]);
              }
            }
          }
        }
        if (itemsTopics && itemsTopics.length) {
          for (let i = 0; i < itemsTopics.length; i++) {
            if (itemsTopics[i].items_data[0]) {
              let find = loadOptions.find(
                (l) => l._id === itemsTopics[i].items_data[0]._id
              );
              if (!find) {
                itemsTopics[i].items_data[0].label =
                  itemsTopics[i].items_data[0].Description;
                itemsTopics[i].items_data[0].redirect = "/hcp/resource/details";
                loadOptions.push(itemsTopics[i].items_data[0]);
              }
            }
          }
        }
        if (items && items.length) {
          for (let i = 0; i < items.length; i++) {
            let find = loadOptions.find((l) => l._id === items[i]._id);
            if (!find) {
              items[i].label = items[i].Description;
              items[i].redirect = "/hcp/resource/details";
              loadOptions.push(items[i]);
            }
          }
        }
        // console.log(loadOptions, "load");
      } else {
        let articles = res.data.data.article;
        let pages = res.data.data.page;
        let menus = res.data.data.menu;
        let careers = res.data.data.careers;
        if (pages) {
          for (let i = 0; i < pages.length; i++) {
            pages[i].label = pages[i].title;
            pages[i].redirect = "/interest_group/details";
            loadOptions.push(pages[i]);
          }
        }
        if (articles) {
          for (let i = 0; i < articles.length; i++) {
            if (articles[i].type === "events") {
              articles[i].label = articles[i].title;
              articles[i].redirect = "/congress_event/calendar/event_details";
            }
            if (articles[i].type === "journals") {
              articles[i].label = articles[i].title;
              articles[i].redirect = "/hcp/journal-details";
            }
            if (articles[i].type === "news") {
              articles[i].label = articles[i].title;
              articles[i].redirect = "/news-details";
            }
            if (
              articles[i].type === "pr_links" ||
              articles.type === "hcp_links" ||
              articles.type === "both_links"
            ) {
              articles[i].label = articles[i].title;
              articles[i].redirect = articles[i].link;
            }
            if (articles[i].type === "newsletters") {
              articles[i].label = articles[i].title;
              articles[i].redirect =
                config.BASE_FILE_URL +
                "Newsletters?file=" +
                articles[i].fileUrl;
            }
            loadOptions.push(articles[i]);
          }
        }
        if (menus) {
          for (let i = 0; i < menus.length; i++) {
            menus[i].label = menus[i].name;
            menus[i].redirect = "/menu" + menus[i].slug;
            loadOptions.push(menus[i]);
          }
        }
        if (careers) {
          for (let i = 0; i < careers.length; i++) {
            careers[i].label = careers[i].title;
            careers[i].redirect = "/member/vacancy/details";
            loadOptions.push(careers[i]);
          }
        }
      }
      this.setState({ loadOptions });
    }
    this.setState({ loading: false });
  };
  getSuggestionValue = (suggestion) => {
    // console.log(suggestion, "in get value");
    if (this.state.searchType === "resouces") return suggestion;
    else return suggestion;
  };
  renderSuggestion = (suggestion) => {
    // console.log("outsideeee", suggestion);
    if (suggestion.label) {
      // console.log("in first block");
      return (
        <div
          style={{ cursor: "pointer" }}
          className="container itemStyle pt-2 pb-2 d-flex"
        >
          <div
            className="pl-2"
            onClick={() => {
              this.setState({ search: "" });
              // console.log(suggestion, "suggestion");
              if (
                suggestion.type &&
                (suggestion.type.includes("_links") ||
                  suggestion.type === "newsletters")
              ) {
                window.open(suggestion.redirect, "_blank");
              } else {
                history.push({
                  pathname: suggestion.redirect,
                  state: suggestion,
                });
                if (history.location.pathname === "/hcp/resource/details") {
                  window.location.reload(false);
                }
              }
            }}
          >
            {suggestion.label}
          </div>
        </div>
      );
    } else {
      // console.log(this.state.loading, "loading");
      return (
        <div
          className="container itemStyle pt-2 pb-2 pl-2 d-flex"
          style={{ textAlign: "center" }}
        >
          {this.state.loadOptions.length <= 1 && <div>No results found</div>}
        </div>
      );
    }
  };
  render() {
    const inputProps = {
      placeholder: "SEARCH",
      value: this.state.search,
      onChange: this.onChange,
    };
    const { SAMenu, HCPMenu, PRMenu, CEMenu } = this.props;
    return (
      <>
        <header
          ref={(divElement) => (this.divElement = divElement)}
          className={this.state.scrolltop ? "is-sticky" : ""}
        >
          {!this.state.visit && (
            <div
              id="cookie-notice"
              className="cookie-revoke-hidden cn-position-top cn-effect-fade cookie-notice-visible"
              aria-label="Cookie Notice"
              style={{ backgroundColor: "#82d9d4" }}
            >
              <div className="cookie-notice-container">
                <span
                  id="cn-notice-text"
                  className="cn-text-container"
                  style={{ color: "#fff" }}
                >
                  We use cookies to ensure that we give you the best experience
                  on our website. If you continue to use this site we will
                  assume that you are happy with it.
                </span>
                <span id="cn-notice-buttons" className="cn-buttons-container">
                  <a
                    href="#"
                    id="cn-accept-cookie"
                    data-cookie-set="accept"
                    className="cn-set-cookie cn-button"
                    style={{ backgroundColor: "#ffffff" }}
                    onClick={() => this.handleCookie()}
                  >
                    OK
                  </a>
                  <a
                    href="https://www.saheart.org/api/get-file/Images?file=49eb7c21-0ba1-4701-8af9-242391d6f6f6.pdf"
                    target="_self"
                    id="cn-more-info"
                    className="cn-more-info cn-button"
                    aria-label="Privacy policy"
                    style={{ backgroundColor: "#ffffff" }}
                  >
                    Privacy Policy
                  </a>
                </span>
                <span
                  id="cn-close-notice"
                  data-cookie-set="accept"
                  className="cn-close-icon"
                  title="No"
                ></span>
              </div>
            </div>
          )}
          <div
            className={
              this.state.scrolltop ? "top-header disable" : "top-header"
            }
          >
            {/* <Container fluid className="custom-width">
              <p
                className="mb-0"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  window.open('https://sacoronavirus.co.za/', '_blank')
                }
              >
                COVID19 - Stay Informed
              </p>
            </Container> */}
          </div>
          <Container fluid className="bg-white custom-width">
            <div className="mid-header">
              <div className="common-mid-header">
                <div className="navbar-brand">
                  <Link
                    to={this.props.userData ? "/member/dashboard" : "/"}
                    onClick={(e) => {
                      if (
                        (this.props.userData &&
                          this.props.userData.status &&
                          this.props.userData.status === "ready") ||
                        (this.props.userData &&
                          this.props.userData.status &&
                          this.props.userData.status === "completed") ||
                        (this.props.userData &&
                          this.props.userData.status &&
                          this.props.userData.status === "expired" &&
                          this.props.userData.membership &&
                          !this.props.userData.membership.length) ||
                        (this.props.userData &&
                          this.props.userData.membership[0] &&
                          this.props.userData.membership[0].status ===
                            "expired")
                      ) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <img
                      src={Main_Logo}
                      alt="main"
                      height="84px"
                      width="101px"
                    />
                  </Link>
                </div>
                {/* Add search-change class after autosuggest */}
                <div
                  className={`search-box  d-md-inline ${
                    this.props.userData ? "d-none" : ""
                  }`}
                >
                  <div className="d-none d-md-flex header-search">
                    <Form>
                      <FormGroup className="header-search-box">
                        {" "}
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col md={8}>
                            <div className="header-search-box input">
                              <Autosuggest
                                className="search-input"
                                suggestions={this.state.loadOptions}
                                onSuggestionsFetchRequested={
                                  this.onSuggestionsFetchRequested
                                }
                                onSuggestionsClearRequested={
                                  this.onSuggestionsClearRequested
                                }
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={this.renderSuggestion}
                                inputProps={inputProps}
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <Dropdown
                              isOpen={this.state.dropdownOpen}
                              toggle={this.toggleDropdown}
                              className="search-toggle"
                            >
                              <DropdownToggle
                                caret
                                className="border-0 w-100 h-100"
                                // style={{
                                //   lineHeight: "1.35rem",
                                //   marginLeft: "-24px",
                                // }}
                              >
                                {this.state.searchType === "resources"
                                  ? "RESOURCES"
                                  : "CONTENT"}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    // console.log("code....");
                                    this.setState({ searchType: "resources" });
                                    localStorage.setItem(
                                      "searchType",
                                      JSON.stringify("resources")
                                    );
                                  }}
                                >
                                  RESOURCES
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    // console.log("description....");
                                    this.setState({ searchType: "content" });
                                    localStorage.setItem(
                                      "searchType",
                                      JSON.stringify("content")
                                    );
                                  }}
                                >
                                  CONTENT
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                            <Button
                              color="secondary"
                              className="icon-search"
                              // style={{ lineHeight: "1.35rem" }}
                            >
                              <BiSearch onClick={(e) => this.handleSubmit(e)} />
                            </Button>
                          </Col>
                        </Row>
                        {/* <InputGroup className="mb-0">
                    <Input placeholder="SEARCH SITE" className="form-control" />
                    <InputGroupText className="p-0">
                      <Button color="secondary">
                        <BiSearch size={24} />
                      </Button>
                    </InputGroupText>
                  </InputGroup> */}
                      </FormGroup>
                    </Form>
                  </div>
                </div>
                <div
                  className={`btn-ul-box d-lg-inline ${
                    this.props.userData ? "d-none" : ""
                  }`}
                >
                  {/* after login */}
                  <ul className={this.props.userData ? "" : "d-none"}>
                    <li>
                      <Link
                        to="#"
                        className="common facebook"
                        onClick={() =>
                          window.open(
                            "https://www.facebook.com/SAHeartassociation/",
                            "_blank"
                          )
                        }
                      >
                        <FaFacebookF size={22} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="common twitter"
                        onClick={() =>
                          window.open(
                            "https://twitter.com/SAHeart_ZA?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwgr%5EeyJ0Zndfc2tlbGV0b25fbG9hZGluZ18xMzM5OCI6eyJidWNrZXQiOiJjdGEiLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3NwYWNlX2NhcmQiOnsiYnVja2V0Ijoib2ZmIiwidmVyc2lvbiI6bnVsbH0sInRmd19ob3Jpem9uX3R3ZWV0X2VtYmVkXzk1NTUiOnsiYnVja2V0IjoiaHRlIiwidmVyc2lvbiI6bnVsbH0sInRmd19zZW5zaXRpdmVfbWVkaWFfaW50ZXJzdGl0aWFsXzEzOTYzIjp7ImJ1Y2tldCI6ImNvbnRyb2wiLCJ2ZXJzaW9uIjo0fSwidGZ3X2V4cGVyaW1lbnRzX2Nvb2tpZV9leHBpcmF0aW9uIjp7ImJ1Y2tldCI6MTIwOTYwMCwidmVyc2lvbiI6bnVsbH19&ref_url=http%3A%2F%2Flocalhost%3A3000%2F",
                            "_blank"
                          )
                        }
                      >
                        <img
                          src={X_Logo}
                          alt="main"
                          height="34px"
                          width="34px"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="common twitter"
                        onClick={() =>
                          window.open(
                            "https://www.linkedin.com/company/sa-heart-association/ ",
                            "_blank"
                          )
                        }
                      >
                        <img
                          src={LinkedIn}
                          alt="main"
                          height="34px"
                          width="34px"
                        />
                      </Link>
                    </li>
                    <li className="naming">
                      <h3>
                        {this.props.userData &&
                        this.props.userData.status === "completed" &&
                        this.props.userData.sageID
                          ? this.props.userData.sageID
                          : ""}
                      </h3>
                      <p className="mb-0 common-16">
                        {this.props.userData && this.props.userData.billingAdd
                          ? this.props.userData.billingAdd.name
                          : ""}
                      </p>
                    </li>
                  </ul>
                  {/* before login */}
                  <ul className={this.props.userData ? "d-none" : ""}>
                    <li>
                      <Link
                        to="#"
                        className="common facebook"
                        onClick={() =>
                          window.open(
                            "https://www.facebook.com/SAHeartassociation/",
                            "_blank"
                          )
                        }
                      >
                        <FaFacebookF size={22} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="common twitter"
                        onClick={() =>
                          window.open(
                            "https://twitter.com/SAHeart_ZA?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwgr%5EeyJ0Zndfc2tlbGV0b25fbG9hZGluZ18xMzM5OCI6eyJidWNrZXQiOiJjdGEiLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3NwYWNlX2NhcmQiOnsiYnVja2V0Ijoib2ZmIiwidmVyc2lvbiI6bnVsbH0sInRmd19ob3Jpem9uX3R3ZWV0X2VtYmVkXzk1NTUiOnsiYnVja2V0IjoiaHRlIiwidmVyc2lvbiI6bnVsbH0sInRmd19zZW5zaXRpdmVfbWVkaWFfaW50ZXJzdGl0aWFsXzEzOTYzIjp7ImJ1Y2tldCI6ImNvbnRyb2wiLCJ2ZXJzaW9uIjo0fSwidGZ3X2V4cGVyaW1lbnRzX2Nvb2tpZV9leHBpcmF0aW9uIjp7ImJ1Y2tldCI6MTIwOTYwMCwidmVyc2lvbiI6bnVsbH19&ref_url=http%3A%2F%2Flocalhost%3A3000%2F",
                            "_blank"
                          )
                        }
                      >
                        <img
                          src={X_Logo}
                          alt="main"
                          height="34px"
                          width="34px"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="common twitter"
                        onClick={() =>
                          window.open(
                            "https://www.linkedin.com/company/sa-heart-association/ ",
                            "_blank"
                          )
                        }
                      >
                        <img
                          src={LinkedIn}
                          alt="main"
                          height="34px"
                          width="34px"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/member/registration_form"
                        className="btn btn-secondary with-gray"
                      >
                        JOIN NOW
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/member/login"
                        className="btn btn-outline-secondary"
                      >
                        MEMBER LOGIN
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="#"
                        className="btn btn-outline-secondary"
                        onClick={() =>
                          window.open(
                            "https://ledimo.co.za/sa_heart_forum/",
                            "_blank"
                          )
                        }
                      >
                        INDUSTRY FORUM
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/hcp/resource/cart"
                        className="btn btn-outline-dark"
                      >
                        <span className="outer-span">
                          <img src={Cart} className="img-fluid" alt="" />
                          <span className="inner-span">
                            {this.props.cartProductCount}
                          </span>
                          cart
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div
                  className={`responsive-common-visible btn-ul-box d-lg-none d-inline ${
                    this.props.userData ? "" : "d-none"
                  }`}
                >
                  <ul>
                    <li>
                      <Link
                        to="#"
                        className="common facebook"
                        onClick={() =>
                          window.open(
                            "https://www.facebook.com/SAHeartassociation/",
                            "_blank"
                          )
                        }
                      >
                        <FaFacebookF size={22} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="common twitter"
                        onClick={() =>
                          window.open(
                            "https://twitter.com/SAHeart_ZA?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwgr%5EeyJ0Zndfc2tlbGV0b25fbG9hZGluZ18xMzM5OCI6eyJidWNrZXQiOiJjdGEiLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3NwYWNlX2NhcmQiOnsiYnVja2V0Ijoib2ZmIiwidmVyc2lvbiI6bnVsbH0sInRmd19ob3Jpem9uX3R3ZWV0X2VtYmVkXzk1NTUiOnsiYnVja2V0IjoiaHRlIiwidmVyc2lvbiI6bnVsbH0sInRmd19zZW5zaXRpdmVfbWVkaWFfaW50ZXJzdGl0aWFsXzEzOTYzIjp7ImJ1Y2tldCI6ImNvbnRyb2wiLCJ2ZXJzaW9uIjo0fSwidGZ3X2V4cGVyaW1lbnRzX2Nvb2tpZV9leHBpcmF0aW9uIjp7ImJ1Y2tldCI6MTIwOTYwMCwidmVyc2lvbiI6bnVsbH19&ref_url=http%3A%2F%2Flocalhost%3A3000%2F",
                            "_blank"
                          )
                        }
                      >
                        <img
                          src={X_Logo}
                          alt="main"
                          height="34px"
                          width="34px"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="common twitter"
                        onClick={() =>
                          window.open(
                            "https://www.linkedin.com/company/sa-heart-association/ ",
                            "_blank"
                          )
                        }
                      >
                        <img
                          src={LinkedIn}
                          alt="main"
                          height="34px"
                          width="34px"
                        />
                      </Link>
                    </li>
                    <li className="naming">
                      <h3>
                        {this.props.userData && this.props.userData.billingAdd
                          ? this.props.userData.billingAdd.name
                          : ""}
                      </h3>
                    </li>
                    {/* collapse button for user panel  */}
                    <li className={`${this.props.userData ? "d-none" : ""}`}>
                      <div className="common align">
                        <AiOutlineAlignLeft
                          size={28}
                          onClick={this.setMidheaderActive}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  className={
                    !this.state.isMidheaderActive
                      ? `responsive-common btn-ul-box d-lg-none d-inline ${
                          !this.props.userData ? "" : "d-none"
                        }`
                      : `show responsive-common btn-ul-box d-lg-none d-inline ${
                          !this.props.userData ? "" : "d-none"
                        }`
                  }
                >
                  <div className={`d-lg-none d-flex sidebar-header`}>
                    <p>SA Heart</p>
                    <AiFillCloseSquare
                      size={30}
                      className="text-danger pointer"
                      onClick={this.setMidheaderActive}
                    />
                  </div>
                  <div className="btn-ul-box">
                    <ul>
                      <li>
                        <Link
                          to="/member/registration_form"
                          className="btn btn-secondary with-gray min-40"
                        >
                          JOIN NOW
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/member/login"
                          className="btn btn-outline-secondary min-40"
                        >
                          MEMBER LOGIN
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="#"
                          className="btn btn-outline-secondary min-40"
                          onClick={() =>
                            window.open(
                              "https://ledimo.co.za/sa_heart_forum/",
                              "_blank"
                            )
                          }
                        >
                          INDUSTRY FORUM
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="/hcp/resource/cart"
                          className="btn btn-outline-dark min-40"
                        >
                          <span className="outer-span">
                            <img src={Cart} className="img-fluid" alt="" />
                            <span className="inner-span">
                              {this.props.cartProductCount}
                            </span>
                            cart
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-header">
              <Navbar color="white" expand="lg" light>
                <NavbarToggler onClick={this.setHeaderActive} />
                {/* After Login Start */}
                {this.props.userData && (
                  <div className="">
                    <NavLink
                      className="nav-link btn btn-secondary btn-sm back d-flex d-lg-none"
                      to="/member/reset-password"
                      style={{ marginRight: "10px" }}
                    >
                      RESET PASSWORD
                    </NavLink>
                    <NavLink
                      className="nav-link btn btn-secondary btn-sm back d-flex d-lg-none"
                      to="/"
                      onClick={() => {
                        window.scroll(0, 0);
                        this.props.logoutWithJWT();
                      }}
                    >
                      LOGOUT
                    </NavLink>
                  </div>
                )}
                {/* After Login End */}
                <Collapse
                  navbar
                  className={this.state.isHeaderActive ? null : "show"}
                >
                  <div className="d-lg-none d-flex sidebar-header">
                    <p>SA Heart</p>
                    <AiFillCloseSquare
                      size={30}
                      className="text-danger pointer"
                      onClick={this.setHeaderActive}
                    />
                  </div>
                  {/* Before Login Start */}
                  <Nav
                    className={`me-auto ${this.props.userData ? "d-none" : ""}`}
                    navbar
                  >
                    {/* SA HEART */}
                    <UncontrolledDropdown
                      inNavbar
                      nav
                      isOpen={this.state.dropdownOpenSA}
                      toggle={this.toggleSA}
                    >
                      <DropdownToggle nav>SA HEART</DropdownToggle>
                      <DropdownMenu>
                        {SAMenu &&
                          SAMenu.map((m) => {
                            return (
                              <Link
                                to={
                                  m.type === "PRE" ? m.slug : `/menu${m.slug}`
                                }
                                className="dropdown-item"
                                onClick={() => {
                                  this.toggleSA();
                                }}
                              >
                                {m.name}
                              </Link>
                            );
                          })}
                        {/* <Link to="/whoweare" className="dropdown-item">
                          WHO WE ARE
                        </Link>
                        <Link to="/affiliations" className="dropdown-item">
                          AFFILIATIONS
                        </Link>
                        <Link to="/board_directors" className="dropdown-item">
                          BOARD OF DIRECTORS
                        </Link>
                        <Link to="/board_committees" className="dropdown-item">
                          BOARD COMMITTEES
                        </Link>
                        <Link
                          to="/standing_committees"
                          className="dropdown-item"
                        >
                          STANDING COMMITTEES
                        </Link>
                        <Link to="/regional_branches" className="dropdown-item">
                          REGIONAL BRANCHES
                        </Link>
                        <Link to="/governance" className="dropdown-item">
                          GOVERNANCE
                        </Link>
                        <Link
                          to="/member/registration"
                          className="dropdown-item"
                        >
                          MEMBER REGISTRATION
                        </Link>
                        <Link to="/member/search" className="dropdown-item">
                          MEMBERS SEARCH
                        </Link> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    {/* INTEREST GROUPS */}
                    <NavItem>
                      <NavLink className="nav-link" to="/interest_group">
                        INTEREST GROUPS
                      </NavLink>
                    </NavItem>

                    {/* CONGRESS & EVENTS */}
                    <UncontrolledDropdown
                      inNavbar
                      nav
                      isOpen={this.state.dropdownOpenCE}
                      toggle={this.toggleCE}
                    >
                      <DropdownToggle nav>CONGRESS & EVENTS</DropdownToggle>
                      <DropdownMenu>
                        {CEMenu &&
                          CEMenu.map((m) => {
                            return (
                              <Link
                                to={
                                  m.type === "PRE" ? m.slug : `/menu${m.slug}`
                                }
                                className="dropdown-item"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (m.type === "CUSTOM" && !m.page) {
                                    window.open(m.link, "_blank");
                                  }
                                  this.toggleCE();
                                }}
                              >
                                {m.name}
                              </Link>
                            );
                          })}
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    {/* HEALTH CARE PROFESSIONALS */}
                    <UncontrolledDropdown
                      inNavbar
                      nav
                      isOpen={this.state.dropdownOpenHCP}
                      toggle={this.toggleHCP}
                    >
                      <DropdownToggle nav>
                        HEALTH CARE PROFESSIONALS
                      </DropdownToggle>
                      <DropdownMenu>
                        {HCPMenu &&
                          HCPMenu.map((m) => {
                            return (
                              <Link
                                to={
                                  m.type === "PRE" ? m.slug : `/menu${m.slug}`
                                }
                                className="dropdown-item"
                                onClick={() => this.toggleHCP()}
                              >
                                {m.name}
                              </Link>
                            );
                          })}
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    {/* PATIENT RESOURCES */}
                    <UncontrolledDropdown
                      inNavbar
                      nav
                      isOpen={this.state.dropdownOpenPR}
                      toggle={this.togglePR}
                    >
                      <DropdownToggle nav className="nav-link">
                        PATIENT RESOURCES
                      </DropdownToggle>
                      <DropdownMenu>
                        {PRMenu &&
                          PRMenu.map((m) => {
                            return (
                              <Link
                                to={
                                  m.type === "PRE" ? m.slug : `/menu${m.slug}`
                                }
                                className="dropdown-item"
                                onClick={() => this.togglePR()}
                              >
                                {m.name}
                              </Link>
                            );
                          })}
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    {/* GROUPS */}
                    {/* <NavItem>
                      <Link className="nav-link" to="/groups">
                        GROUPS
                      </Link>
                    </NavItem> */}
                  </Nav>
                  {/* Before Login End */}

                  {/* After Login Start */}
                  <Nav
                    className={`me-auto ${this.props.userData ? "" : "d-none"}`}
                    navbar
                  >
                    <NavItem>
                      <NavLink
                        className="nav-link"
                        to="/member/dashboard"
                        onClick={(e) => {
                          if (
                            (this.props.userData &&
                              (this.props.userData.status === "ready" ||
                                this.props.userData.status === "completed")) ||
                            (this.props.userData.status &&
                              this.props.userData.status === "expired" &&
                              this.props.userData.membership &&
                              !this.props.userData.membership.length) ||
                            (this.props.userData.membership[0] &&
                              this.props.userData.membership[0].status ===
                                "expired")
                          ) {
                            e.preventDefault();
                          }
                        }}
                      >
                        DASHBOARD
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="nav-link"
                        to="/hcp_resources"
                        onClick={(e) => {
                          if (
                            this.props.userData &&
                            (this.props.userData.status === "ready" ||
                              this.props.userData.status === "completed" ||
                              (this.props.userData.status &&
                                this.props.userData.status === "expired" &&
                                this.props.userData.membership &&
                                !this.props.userData.membership.length) ||
                              (this.props.userData.membership[0] &&
                                this.props.userData.membership[0].status ===
                                  "expired"))
                          ) {
                            e.preventDefault();
                          }
                        }}
                      >
                        RESOURCES
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="nav-link"
                        to="/member/vacancies"
                        onClick={(e) => {
                          if (
                            (this.props.userData &&
                              (this.props.userData.status === "ready" ||
                                this.props.userData.status === "completed")) ||
                            (this.props.userData.status &&
                              this.props.userData.status === "expired" &&
                              this.props.userData.membership &&
                              !this.props.userData.membership.length) ||
                            (this.props.userData.membership[0] &&
                              this.props.userData.membership[0].status ===
                                "expired")
                          ) {
                            e.preventDefault();
                          }
                        }}
                      >
                        VACANCIES
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="nav-link"
                        to="/member/account_details"
                        onClick={(e) => {
                          if (
                            this.props.userData &&
                            (this.props.userData.status === "ready" ||
                              this.props.userData.status === "completed" ||
                              (this.props.userData.status &&
                                this.props.userData.status === "expired" &&
                                this.props.userData.membership &&
                                !this.props.userData.membership.length) ||
                              (this.props.userData.membership[0] &&
                                this.props.userData.membership[0].status ===
                                  "expired"))
                          ) {
                            e.preventDefault();
                          }
                        }}
                      >
                        ACCOUNT DETAILS
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="nav-link"
                        to="/member/my_membership"
                        onClick={(e) => {
                          if (
                            this.props.userData &&
                            (this.props.userData.status === "ready" ||
                              this.props.userData.status === "completed" ||
                              (this.props.userData.status &&
                                this.props.userData.status === "expired" &&
                                this.props.userData.membership &&
                                !this.props.userData.membership.length) ||
                              (this.props.userData.membership[0] &&
                                this.props.userData.membership[0].status ===
                                  "expired"))
                          ) {
                            e.preventDefault();
                          }
                        }}
                      >
                        MY MEMBERSHIP
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="nav-link"
                        to="/congress-click"
                        onClick={(e) => {
                          console.log(
                            "this.props.userData: ",
                            this.props.userData
                          );
                          if (
                            this.props.userData &&
                            (this.props.userData.status === "ready" ||
                              this.props.userData.status === "completed" ||
                              (this.props.userData.membership[0] &&
                                this.props.userData.membership[0].status ===
                                  "expired"))
                          ) {
                            e.preventDefault();
                          } else {
                            e.preventDefault();
                            if (this.props?.userData?.isActive) {
                              this.handleCongressClick();
                            }
                          }
                        }}
                      >
                        MEMBER CONGRESS REGISTRATION
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {this.props.userData && (
                    <>
                      <div className="">
                        {" "}
                        <NavLink
                          className="nav-link btn btn-secondary btn-sm back d-flex"
                          to="/member/reset-password"
                          style={{ marginRight: "10px" }}
                        >
                          RESET PASSWORD
                        </NavLink>
                      </div>
                      <div className="">
                        <NavLink
                          className="nav-link btn btn-secondary btn-sm back d-flex"
                          to="/"
                          onClick={() => {
                            window.scroll(0, 0);
                            this.props.logoutWithJWT();
                          }}
                        >
                          LOGOUT
                        </NavLink>
                      </div>
                    </>
                  )}
                  {/* After Login End */}
                </Collapse>
              </Navbar>
            </div>
          </Container>
        </header>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log("State in header", state);
  return {
    SAMenu: state.cms.Menu.saMenuList,
    HCPMenu: state.cms.Menu.hcpMenuList,
    CEMenu: state.cms.Menu.ceMenuList,
    PRMenu: state.cms.Menu.prMenuList,
    cartProductCount: state.searchMyCatalogue.cartCount,
    userData: state.auth.login.values.loggedInUser,
  };
};

export default withCookies(
  connect(mapStateToProps, {
    getAllMenu,
    CartB2CApi,
    logoutWithJWT,
  })(Header)
);
